import React, { useState } from 'react';
import '../styles/slider.css';
import { FiPlusCircle,FiMinusCircle  } from "react-icons/fi";
import { MdOutlineCancel } from "react-icons/md";

export const Blogs = ({cards,targetId,subtitle}) => {
    const [id, setId] = useState(null);
   
    return (
        <>
       
      <div className='slider-container m-auto'>
        <div id={targetId} className='cards-container mt-6 lg:mt-10 4k:mt-28   '>
          {cards.map((card, index) => (
            <div
              key={`blog#${index}`}
              className={`${index === 0 ? '' : 'ml-6 xl:ml-10'}`}
             
            >
                {subtitle?
                <div className='h-8 lg:h-12 text-[17px] w-full' style={{fontWeight:500}}>{card.heading}</div>:null}
                <div className='relative'>
                <div className='text-dark-3 hover:text-header-primary select-none'>
                  <div
                    className='w-72 h-64 lg:w-80 lg:h-64 2xl:w-[28rem] 2xl:h-96 xxl:w-96 xxl:h-80 4k:w-blog-img 4k:h-blog-img bg-cover bg-center bg-no-repeat rounded-t-xl'
                    style={{ backgroundImage: `url("${card.imgSrc}")` }}
                  ></div>
                  <div className='flex justify-between h-20 items-center rounded-b-xl px-2 bg-[#F3f3f3]'>
                  {id !== index && (
                    <><div className='text-[20px] leading-[1.5rem] tracking-[0.025rem] 4k:text-3xl'>
                      {card.title}
                    </div>  
                    <div className='w-10 h-10 cursor-pointer' onClick={()=>{setId(index)}}>
                    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.0557 21C12.0557 20.2943 12.6277 19.7223 13.3334 19.7223H19.7223V13.3334C19.7223 12.6277 20.2944 12.0556 21.0001 12.0556C21.7058 12.0556 22.2779 12.6277 22.2779 13.3334V19.7223H28.6668C29.3725 19.7223 29.9446 20.2943 29.9446 21C29.9446 21.7057 29.3725 22.2778 28.6668 22.2778H22.2779V28.6667C22.2779 29.3724 21.7058 29.9445 21.0001 29.9445C20.2944 29.9445 19.7223 29.3724 19.7223 28.6667V22.2778H13.3334C12.6277 22.2778 12.0557 21.7057 12.0557 21Z" fill="#2E2E2E"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.0001 0.555588C9.70895 0.555588 0.555664 9.70888 0.555664 21C0.555664 32.2912 9.70895 41.4445 21.0001 41.4445C32.2913 41.4445 41.4446 32.2912 41.4446 21C41.4446 9.70888 32.2913 0.555588 21.0001 0.555588ZM3.11122 21C3.11122 30.8798 11.1203 38.8889 21.0001 38.8889C30.8799 38.8889 38.889 30.8798 38.889 21C38.889 11.1203 30.8799 3.11114 21.0001 3.11114C11.1203 3.11114 3.11122 11.1203 3.11122 21Z" fill="#2E2E2E"/>
</svg></div>  </>   )}    
                  </div>
                </div>
                {id === index && (
                                <div className="absolute top-0 left-0 w-full h-full bg-[#F3f3f3] rounded-xl bg-opacity-80">
                                    <div className="max-w-2xl">
                                        <h2 className="text-xl font-semibold p-4">{card.title}</h2>
                                        <p className='px-4' dangerouslySetInnerHTML={{ __html: card.description }}/>
                                        {/* You can include other details here */}
                                    </div>
                                    <div className='w-10 h-10 cursor-pointer absolute bottom-5 right-2' onClick={()=>{setId(null)}}>
                                    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.675 27.3248C14.176 26.8258 14.176 26.0167 14.675 25.5177L19.1927 21.0001L14.675 16.4825C14.176 15.9835 14.176 15.1744 14.675 14.6754C15.174 14.1764 15.9831 14.1764 16.4821 14.6754L20.9997 19.1931L25.5173 14.6754C26.0164 14.1764 26.8254 14.1764 27.3244 14.6754C27.8234 15.1744 27.8234 15.9835 27.3244 16.4825L22.8068 21.0001L27.3244 25.5177C27.8234 26.0167 27.8234 26.8258 27.3244 27.3248C26.8254 27.8238 26.0164 27.8238 25.5173 27.3248L20.9997 22.8072L16.4821 27.3248C15.9831 27.8238 15.174 27.8238 14.675 27.3248Z" fill="#2E2E2E"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.54332 6.5437C-1.44073 14.5278 -1.44073 27.4725 6.54332 35.4565C14.5274 43.4406 27.4721 43.4406 35.4561 35.4565C43.4402 27.4725 43.4402 14.5278 35.4561 6.5437C27.4721 -1.44035 14.5274 -1.44035 6.54332 6.5437ZM8.35037 33.6495C15.3364 40.6355 26.663 40.6355 33.6491 33.6495C40.6351 26.6634 40.6351 15.3368 33.6491 8.35075C26.663 1.36471 15.3364 1.36471 8.35037 8.35075C1.36432 15.3368 1.36432 26.6634 8.35037 33.6495Z" fill="#2E2E2E"/>
</svg>

                                      </div> 
                                </div>
                            )}
                            </div>
            </div>
          ))}
        </div>
        
      </div>
      </>
    );
  };