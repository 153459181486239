// App.js

import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import { Element } from 'react-scroll';
import Banner from './views/Banner';
import Footer from './views/Footer';
import DigitalizationSection from "./views/DigitalizationSection";
import Whoweare from './views/Whoweare';
import Healthcarechallenges from './views/Healthcarechallenges';
import Navbar from './views/Navbar';
import Stakeholders from './views/Stakeholders';
import SliderBanner from './views/SliderBanner';
import { About } from './views/About';

function App() {
  const bannerRef = useRef(null);
  const aboutRef = useRef(null);
  const whoerareRef = useRef(null);
  const challengesRef = useRef(null);
  const digitalizationRef = useRef(null);
  const valuePropRef=useRef(null)

  const stakeholdersRef = useRef(null);
  const [visibleRef, setVisible] = useState(null);


  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.4, // Trigger when 20% of the element is visible
    };

    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          console.log("30",entry.target.getAttribute('name'))
          setVisible(entry.target.getAttribute('name'));
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);

    // Observe each ref
    [bannerRef, aboutRef, whoerareRef, challengesRef, digitalizationRef, valuePropRef, stakeholdersRef].forEach(ref => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    // Clean up observer on unmount
    return () => {
      observer.disconnect();
    };
  }, []);
  return (
    <div className="App">
      <Navbar
        visibleRef={visibleRef}
        handleNavbarClick={() => bannerRef.current?.scrollIntoView({ behavior: 'smooth' })}
        handleAbout={() => aboutRef.current?.scrollIntoView({ behavior: 'smooth' })}
        handleChallenges={() => challengesRef.current?.scrollIntoView({ behavior: 'smooth' })}
        handleDigitalization={() => digitalizationRef.current?.scrollIntoView({ behavior: 'smooth' })}
        handleValueprop={() => valuePropRef.current?.scrollIntoView({behavior:'smooth'})}
        handleStakehoder={() => stakeholdersRef.current?.scrollIntoView({ behavior: 'smooth' })}
      />
      <div className="container2">
        <div className={`section ${visibleRef === 'banner' ? 'fade-in' : 'fade-out'}`} name="banner" ref={bannerRef}>
          <Banner handleAbout={() => aboutRef.current?.scrollIntoView({ behavior: 'smooth' })} />
        </div>
        <div className={`section ${visibleRef === 'about' ? 'fade-in' : 'fade-out'}`} name="about" ref={aboutRef}>
          <About />
        </div>
       
        <div className={`section ${visibleRef === 'healthcarechallenges' ? 'fade-in' : 'fade-out'}`} name="healthcarechallenges" ref={challengesRef}>
          <Healthcarechallenges />
        </div>
        <div className={`section ${visibleRef === 'digitalization' ? 'fade-in' : 'fade-out'}`} name="digitalization" ref={digitalizationRef}>
          <DigitalizationSection />
        </div>
          <div className={`section ${visibleRef === 'sliderbanner' ? '' : ''}`} name="sliderbanner" ref={valuePropRef}>
          <SliderBanner visibleRef={visibleRef} setVisible={setVisible}/>
          </div>
         
        <div className={`section ${visibleRef === 'stakeholders' ? 'fade-in' : 'fade-out'}`} name="stakeholders" ref={stakeholdersRef}>
          <Stakeholders />
        </div>
        <div className={`section ${visibleRef === 'whoweare' ? 'fade-in' : 'fade-out'}`} name="whoweare" ref={whoerareRef}>
          <Whoweare />
        </div>
        <Element className="section" name="footer">
          <Footer
            handleNavbarClick={() => bannerRef.current?.scrollIntoView({ behavior: 'smooth' })}
            handleAbout={() => aboutRef.current?.scrollIntoView({ behavior: 'smooth' })}
            handleChallenges={() => challengesRef.current?.scrollIntoView({ behavior: 'smooth' })}
            handleDigitalization={() => digitalizationRef.current?.scrollIntoView({ behavior: 'smooth' })}
            handleValueprop={() => valuePropRef.current?.scrollIntoView({ behavior: 'smooth' })}
            handleStakehoder={() => stakeholdersRef.current?.scrollIntoView({ behavior: 'smooth' })}
          />
        </Element>
      </div>
    </div>
  );
}

export default App;
