import React, { useState } from "react";
import "./Landing.css";
import { IoArrowDown } from "react-icons/io5";
import { Link } from 'react-scroll';
const Banner = ({handleAbout}) => {
  const [isBouncing, setIsBouncing] = useState(false);

  const handleClick = () => {
    setIsBouncing(true);
    setTimeout(() => {
      setIsBouncing(false);
      handleAbout()
    }, 400); // Adjust the timing to match your animation
  };
  return (
    <div className="landingpage" id="Banner">
        <img src={"/Banner/CoverImage.jpg"} class="video-bg hidden lg:block" alt="" />
        <img src={"/Banner/CoverImageMobile.jpg"} class="video-bg block lg:hidden" alt="" />
      <div className="bgoverlay top-[18%] lg:top-[25%]">
        {" "}
        <div className="text-white text-[45px] md:text-[55px]">
        Digitalizing Health, 
        </div>
        <div className="text-white text-[45px] md:text-[55px]">
        Empowering Governments, 
        </div>
        <div className="text-white text-[45px] md:text-[55px]">
        Enriching Lives
        </div>
        <div className={`bouncing-button ${isBouncing ? 'bounce-and-drop' : ''} mt-6 lg:mt-12 text-white cursor-pointer`} onClick={()=>{
          handleClick()
        }}>
         <svg xmlns="
http://www.w3.org/2000/svg"
width="77" height="77" viewBox="0 0 97 97" fill="none">
<rect x="22.5" y="11.5" width="51" height="74" rx="25.5" stroke="white" stroke-width="3"/>
<path d="M49.5 26C49.5 25.1716 48.8284 24.5 48 24.5C47.1716 24.5 46.5 25.1716 46.5 26H49.5ZM46.5 26V54H49.5V26H46.5Z" fill="white"/>
<path d="M35.5 43.5L48 56L61 43.5" stroke="white" stroke-width="3" stroke-linecap="round"/>
</svg>
        </div>
      </div>
    </div>
  );
};

export default Banner;
