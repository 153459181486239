import React from "react";
import SliderButtons from "../components/SliderButtons";
import { Blogs } from "../components/Slider";

export default function DigitalizationSection() {
  const cards = [
    {
      id: 1,
      imgSrc: '/digitalization/Efficieny&Transparency.jpg',
      title: 'Efficiency and Transparency',
      heading:"Digital Transformation helps achieve​",
      description:"Data science and Machine Learning saves European healthcare nearly <span class='font-bold'>$220 billion</span> annually. Technology-driven innovation will create <span class='font-bold'>$350–$410 billion</span> in annual value by 2025."
    },
    {
      id: 2,
      imgSrc: '/digitalization/UtilizationRelative.jpg',
      title: 'Utilization Relative to Need',
      description:"Telehealth adoption expands patient coverage and saves <span class='font-bold'>$250 billion</span> in US healthcare spend. By 2050, remote patient monitoring would reduce health service gap, saving <span class='font-bold'>$200 billion</span> globally."
    },
    {
      id: 3,
      imgSrc: '/digitalization/ImprovedQuality.jpg',
      title: 'Improved Care Quality',
      description:"Enhanced Patient care through value-based care models in the US (estimated spend $1.7 T), will lead to <span class='font-bold'>3-20%</span> lower medical spend. Globally, high-quality health systems can prevent <span class='font-bold'>15 million</span> deaths each year."
    },
    {
      id: 4,
      imgSrc: '/digitalization/LifeSaved.jpg',
      title: 'Lives Saved',
      heading:"Significant health outcomes​",
      description:"Wearable devices and sensors can save <span class='font-bold'>2 million</span> lives per year and IoT can prevent errors and save up to <span class='font-bold'>1 million</span> lives annually."
    },
    {
        id: 5,
        imgSrc: '/digitalization/TimeSaved.jpg',
        title: 'Time Saved',
        description:"AI applications in virtual health assistance free up <span class='font-bold'>1.8 billion</span> hours annually in Europe; equivalent to having <span class='font-bold'>500K</span> full-time health care providers."
      },
      {
        id: 6,
        imgSrc: '/digitalization/CostSaved.jpg',
        title: 'Cost Saved',
        description:"AI in healthcare can save <span class='font-bold'>$150 billion</span> annually and Revenue Cycle Management can save <span class='font-bold'>$140 billion</span>."
      }
  ];
  return (
    <div id="digitalization" className="pt-2 lg:pt-6">
		<div className="lg:w-[1140px] 2xl:w-[1680px] mx-auto px-4 mb-10" >
		<div
			
			className="mb-2 m-auto pt-14 w-[100%] h-[100%] flex justify-between flex-row text-center items-center sm:text-[26px] lg:text-[30px] md:text-[30px]"
		>
			<div
				className="text-[25px] lg:text-[36px] md:text-[32px] font-medium text-[#002475] text-left mb-2
"
			>
				Need for Health Digitalization
			</div>
			<hr className='hidden lg:block lg:w-[40%] border-top-[0.5px] border-black'/>
			<div className='hidden lg:block'>
        <SliderButtons
          mainContainerClass=' flex items-end gap-5 4k:gap-7 pb-1'
          buttonClass=' outline-none uppercase text-black border-2 bg-light rounded-full hover:bg-gray-200 p-1 lg:p-3 '
          svgClass=' h-6 4k:h-12 w-6 4k:w-12 '
          svgContainerClass=' grid place-items-center '
          targetId='digitalization-card'
          slide="full"
        />
      </div>
	  
		</div>
    <div className="block lg:hidden text-[20px] leading-6" style={{fontWeight:350}}>
    At Apeiro, we are on a mission to improve patient care through digitalization. We empower governments to revolutionize healthcare by providing high-quality services and creating a better future for citizens.
		</div>
		<div className="hidden lg:block text-[20px] leading-6" style={{fontWeight:350}}>
		At Apeiro, we are on a mission to improve patient care through digitalization.
		</div>
		<div className="hidden lg:block text-[20px] leading-6" style={{fontWeight:350}}>
    We empower governments to revolutionize healthcare by providing high-quality services and creating a better future for citizens.
		</div>
		<Blogs cards={cards} targetId={"digitalization-card"} subtitle={true}/>
		</div>
    </div>
	);
}
