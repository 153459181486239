import React, { useState, useRef,useEffect } from "react";
import "swiper/swiper-bundle.css";
import { Pagination,Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from "swiper/react";
export const About=()=>{
   const swiperRef = useRef(null);
	const containerRef = useRef(null); // Ref for the container
    return (
      <div id="aboutus" className="">
		<div >
 <div  className="hidden lg:grid grid-cols-7 h-screen px-20 gap-4 pt-20 pb-5">
         <div className="grid grid-rows-2 col-span-4 grid-cols-2 gap-4">
         <div className="col-span-2 rounded-xl p-6" style={{backgroundColor:"rgba(196, 196, 196, 0.2)"}}>
            <div className="lg:text-[40px] mb-3 text-[#002475]" style={{fontWeight:600}}>About Us</div>
            <div className="text-[18px] leading-6" style={{fontWeight:350}}>Apeiro, a subsidiary of Sirius International Holding, was founded to address challenges faced by governments in managing healthcare systems. Our aim is to ensure that everyone can access high-quality medical care, contributing to financially sustainable universal health coverage for all. We stand at the junction of Health and Technology, offering countries assistance throughout their journey of healthcare transformation.</div>
         </div>
         <div className="rounded-xl p-6" style={{backgroundColor:"rgba(196, 196, 196, 0.2)"}}>
            <div className="text-[40px] mb-3 text-[#002475]" style={{fontWeight:600}}>Mission</div>
            <div className="text-[18px] leading-6" style={{fontWeight:350}}>Our mission is to revolutionize healthcare through digital transformation, in partnership with government entities, to ensure both financial viability and the highest standards of clinical care.</div>
         </div>
         <div className="rounded-xl p-6" style={{backgroundColor:"rgba(196, 196, 196, 0.2)"}}>
            <div className="text-[40px] mb-3 text-[#002475]" style={{fontWeight:600}}>Vision</div>
            <div className="text-[18px] leading-6" style={{fontWeight:350}}>We envision a future where digital healthcare solutions, developed through robust public-private partnerships, set the gold standard for excellence and sustainability in global health services.</div>
         </div>
         </div>
         <div className="col-span-3 rounded-xl bg-cover bg-left" style={{backgroundImage:'url(/AboutUs/About2.jpg)'}}>
         </div>
        </div>
<div className="lg:hidden w-[100%] h-[75vh] lg:h-[100vh] lg:px-[120px] pt-4" ref={containerRef} 
				>
				<Swiper
									slidesPerView={1}
					// 					autoplay={{
					// 	delay: 2000,
					// 	disableOnInteraction: false,
					// }}
					ref={swiperRef}
					speed={2000}
					// modules={[Pagination, Autoplay]}
					modules={[Pagination]}

					pagination={{
						clickable: true,
					}}
				>
				<SwiperSlide className="w-[100%]">
						<div className="mx-4 my-12 rounded-xl p-6 h-[65vh] lg:h-[85vh]" style={{backgroundColor:"rgba(196, 196, 196, 0.2)"}}>
							

                     <div className="text-[32px] mb-4 text-[#002475]" style={{fontWeight:600}}>About Us</div>
            <div className="text-[18px] leading-6"  style={{fontWeight:350}}>Apeiro was founded to address challenges faced by governments in managing healthcare systems. Our organization is a subsidiary of Sirius International Holding. Apeiro stands at the junction of Health and Technology, offering countries assistance throughout their journey of healthcare transformation. Our aim is to ensure everyone can access high-quality medical care, contributing to financially sustainable universal health coverage for all.</div>
												
						</div>
					</SwiperSlide>
					<SwiperSlide className="w-[100%]">
						<div className="mx-4 my-12 rounded-xl p-6  h-[65vh] lg:h-[85vh]" style={{backgroundColor:"rgba(196, 196, 196, 0.2)"}}>
                  <div className="text-[32px] mb-4 text-[#002475]"  style={{fontWeight:600}}>Mission</div>
            <div className="text-[18px] leading-6"  style={{fontWeight:350}}>Our mission is to revolutionize healthcare through digital transformation, in partnership with government entities, to ensure both financial viability and the highest standards of clinical care.</div>
						</div>
					</SwiperSlide>

					<SwiperSlide className="w-[100%]">
						<div className="mx-4 my-12 rounded-xl p-6  h-[65vh] lg:h-[85vh]" style={{backgroundColor:"rgba(196, 196, 196, 0.2)"}}>
                  <div className="text-[32px] mb-4 text-[#002475]" style={{fontWeight:600}}>Vision</div>
            <div className="text-[18px] leading-6" style={{fontWeight:350}}>We envision a future where digital healthcare solutions, developed through robust public-private partnerships, set the gold standard for excellence and sustainability in global health services.</div>
						</div>
					</SwiperSlide>
					
				</Swiper>
				<div className="swiper-pagination">
				</div>
			</div>
      </div>
	  </div>
    )
}