import React, { useState } from "react";

export default function Stakeholders() {
  const details = [
    {
      title: "Payors",
      list: [
        "Insurance Management Platform",
        "Claim Processing Automation",
        "Fraud/Waste/Abuse Detection",
        "Chronic Disease Management Platform",
        "Digital Enrollment and Eligibility",
        "Member Engagement and Wellness",
      ],
      image: "url(/Stakeholders/Payers.jpg)",
      imageMob: "/Stakeholders/Payers.jpg",

    },
    {
      title: "Providers",
      list: [
        "Health Information Exchange/HIS/EHR/LIS",
        "Unified Interface/Universal Health Record",
        "E-Orders",
        "Clinical Decision Support Systems (CDSS)",
        "Telemedicine/TeleRAD/TeleICU",
      ],
      image: "url(/Stakeholders/Providers.jpg)",
      imageMob: "/Stakeholders/Providers.jpg",

    },
    {
      title: "Regulators",
      list: [
        "Disease Registries",
        "Licensing Platforms",
        "Population Health Management Platforms",
        "National Digital Health ID",
        "Disease Surveillance Management",
      ],
      image: "url(/Stakeholders/Regulators.jpg)",
      imageMob: "/Stakeholders/Regulators.jpg",

    },
    {
      title: "Research and Educational Institutes",
      list: [
        "Clinical Research Data Management",
        "Research data and Natural Language Processing",
        "Drug Safety Systems",
        "Digital Clinical Pathways",
        "Patient Recruitment and Retention ",
      ],
      image: "url(/Stakeholders/Research&EducationalInstitutions.jpg)",
      imageMob: "/Stakeholders/Research&EducationalInstitutions.jpg",

    },
    {
      title: "Supply Chain and Manufacturing",
      list: [
        "Supply Chain Track and Trace",
        "Order Management",
        "Tender Management",
        "Logistics Management",
        "Enterprise Resource Planning",
      ],
      image: "url(/Stakeholders/SupplyChain.jpg)",
      imageMob: "/Stakeholders/SupplyChain.jpg",

    },
  ];
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div id="stakeholders" className="">
      <div className="bg-white h-screen">
        <div className="lg:w-[1140px] 2xl:w-[1680px] mx-auto pb-10  px-4 h-full pt-0 lg:pt-6">
          <div className="m-auto pt-14 w-[100%] flex justify-between flex-row text-center items-center sm:text-[26px] lg:text-[30px] md:text-[30px]">
            <div
              className="text-[25px] lg:text-[36px] md:text-[32px] text-[#002475] mb-2"
              style={{ fontWeight: 600 }}
            >
              Stakeholders
            </div>
            <hr className="border-top-[0.5px] text-[20px] border-black w-[50%] lg:w-[80%]" />
          </div>
          <div
            className="hidden lg:block mb-6 text-[20px] leading-6"
            style={{ fontWeight: 350 }}
          >
            <div>
              Apeiro provides a comprehensive suite of solutions for every
              stakeholder within the healthcare value chain.{" "}
            </div>
            <div>
              A ‘fit-for-purpose’ customized aggregation of interoperable and
              integrated technology platforms.
            </div>
          </div>
          <div
            className="block lg:hidden mb-2 text-[20px] leading-6"
            style={{ fontWeight: 350 }}
          >
            Apeiro provides a comprehensive suite of solutions for every
            stakeholder within the healthcare value chain. A ‘fit-for-purpose’
            customized aggregation of interoperable and integrated technology
            platforms.
          </div>
          <div className="hidden md:flex text-white shadow-2xl">
            {details.map((elem, index) => (
              <div
                key={index}
                className={`cursor-pointer  h-96 relative transition-columns overflow-hidden ${activeIndex === index ? "basis-5/12" : "basis-2/12"
                  }`}
                onClick={() => setActiveIndex(index)}
              >
                <div
                  className={`${index === 0 ? "rounded-l-md bg-right" : index === details.length - 1 ? "rounded-r-md " : null} bg-cover bg-center bg-no-repeat h-full`}
                  style={{
                    backgroundImage: `linear-gradient(90deg,rgba(0, 0, 0, 0.71) 0%,rgba(0, 0, 0, 0.00) 225%) , ${elem.image}`,
                  }}
                ></div>
                <div className=" text-[22px] absolute bottom-0 left-16 transform -translate-x-4 -translate-y-4 -rotate-90 origin-bottom-left w-96 h-[40px]">
                  {elem.title}
                </div>
                {activeIndex === index ? (
                  <div
                    id="card"
                    className="absolute bottom-4 left-12 w-full "
                    style={{ animation: "slideInRight 3s forwards" }}
                  >
                    {/* <div className="text-[22px]">{elem.title}</div> */}
                    <ul className="list-disc ml-6">
                      {elem.list.map((elements, i) => (
                        <li key={i} className="leading-7 text-[18px]">
                          {elements}
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  null
                )}
              </div>
            ))}
          </div>

          <div className="md:hidden flex flex-col text-black">
            {details.map((elem, index) => (
              <div className="border-b border-gray-200">
                <button
                  className="w-full py-2 text-left flex justify-between items-center"
                  onClick={() => setActiveIndex(index)}
                >
                  <span className="text-[20px]">{elem.title}</span>
                  {activeIndex !== index && <svg
                    className={`transform transition-transform duration-300 ${activeIndex === index ? 'rotate-45' : ''}`}
                    width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.5 8H1.5C1.21667 8 0.979167 7.90417 0.7875 7.7125C0.595833 7.52083 0.5 7.28333 0.5 7C0.5 6.71667 0.595833 6.47917 0.7875 6.2875C0.979167 6.09583 1.21667 6 1.5 6H6.5V1C6.5 0.716667 6.59583 0.479167 6.7875 0.2875C6.97917 0.0958333 7.21667 0 7.5 0C7.78333 0 8.02083 0.0958333 8.2125 0.2875C8.40417 0.479167 8.5 0.716667 8.5 1V6H13.5C13.7833 6 14.0208 6.09583 14.2125 6.2875C14.4042 6.47917 14.5 6.71667 14.5 7C14.5 7.28333 14.4042 7.52083 14.2125 7.7125C14.0208 7.90417 13.7833 8 13.5 8H8.5V13C8.5 13.2833 8.40417 13.5208 8.2125 13.7125C8.02083 13.9042 7.78333 14 7.5 14C7.21667 14 6.97917 13.9042 6.7875 13.7125C6.59583 13.5208 6.5 13.2833 6.5 13V8Z" fill="#1C1B1F" />
                  </svg>}
                  {/* 
       <svg
         width="24"
         height="24"
         fill="none"
         stroke="currentColor"
         viewBox="0 0 24 24"
         xmlns="http://www.w3.org/2000/svg"
       >
         <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
       </svg> */}
                </button>
                {activeIndex === index && (
                  <div className="relative text-white">
                    <div className={`transition-max-height duration-500 ease-in-out bg-cover bg-center bg-no-repeat h-44`} style={{ backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)) , ${elem.image}` }}></div>
                    <ul className="list-disc absolute bottom-0 left-0 ml-6 mb-1">
                      {elem.list.map((elements, i) => (
                        <li key={i} className="leading-6 text-[16px]">
                          {elements}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
