
import React from 'react';
import "./Landing.css";
import { CiSearch } from "react-icons/ci";
import { FaFacebookF, FaInstagram, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { Link } from 'react-scroll';
import { MdOutlineMailOutline } from "react-icons/md";
import logo2 from "../media/logo2.png";
const Footer = ({handleNavbarClick,handleAbout,handleChallenges,handleDigitalization,handleValueprop,handleStakehoder}) => {
  const handleEmailClick = () => {
    const email = 'info@apeiro.digital';
    const mailtoLink = `mailto:${email}`;
    window.location.href = mailtoLink;
};
  return (
    <>
      <footer className="py-4 px-[4%] flex flex-col lg:flex-row footerColor foot text-[12px]">
        <div className="flex flex-col text-left w-[50%] md:text-[14px]">
          <img src={logo2} alt="Logo" className="h-12 w-20 mb-3 " />
          <p className=' text-[#D9D9D9]'>Digitalizing Health, Empowering Governments, Enriching Lives</p>
          {/* <p className='mt-4'>Contact : <span className="cursor-pointer hover:text-white" onClick={handleEmailClick}>info@apeiro.digital</span> </p> */}
          <div className="flex lg:mt-5">
            {/* <div className='rounded-full mr-2 p-2 footer'><FaFacebookF /></div>
            <div className='rounded-full mr-2 p-2 footer'><FaInstagram /> </div>
            <div className='rounded-full mr-2 p-2 footer'><FaTwitter /></div> */}
            <div className='rounded-full mr-2 p-2 footer  text-white'><a href='https://www.linkedin.com/company/apeiroholding/' target='_blank' rel='noopener noreferrer'><FaLinkedinIn /> </a></div>
            <p className='rounded-full mr-2 p-2 footer  text-white cursor-pointer'><MdOutlineMailOutline onClick={handleEmailClick}/> </p>
          </div>
          
        </div>
        <div className='flex lg:justify-evenly w-[50%] mt-4 lg:mt-0'>
          <div className="flex flex-col text-left pr-12 ">
            <div className='text-white my-1 md:text-[18px]'>Quick Links</div>
            <div className='my-1 md:text-[16px] cursor-pointer text-[#D9D9D9]' onClick={()=>{
              handleAbout()
            }}>About Us</div>

            <div className='my-1  md:text-[16px] cursor-pointer text-[#D9D9D9]' onClick={()=>{
              handleChallenges()
            }}>What We See</div>

            <div className='my-1  md:text-[16px] cursor-pointer text-[#D9D9D9]' onClick={handleDigitalization}>Digitalization</div>

            <div className='my-1  md:text-[16px] cursor-pointer text-[#D9D9D9]'  onClick={handleValueprop}>Value Proposition </div>

            <div className='my-1  md:text-[16px] cursor-pointer text-[#D9D9D9]' onClick={handleStakehoder}>Solutions</div>


          </div>

          {/* <div className="flex flex-col text-left">
            <p className='text-white my-1'>Information</p>
            <p className='my-1'>Privacy Policy</p>

            <p className='my-1'>Terms & Condition</p>
            <p className='my-1'>FAQ</p>
          </div> */}
        </div>
      </footer>
      <div className="px-[4%] py-4 flex footerColor text-[white] text-[12px] md:text-[15px]">
      <div className="flex flex-col text-left w-[50%] md:text-[14px]">
      <p className='w-[50%] text-[#D9D9D9]'>Copyright © 2024 Apeiro</p>
      </div>
      <div className='hidden md:flex justify-evenly w-[50%]'>
          <div className="flex flex-col text-left pr-12 footerColor text-[white] text-[12px] md:text-[14px]">
      <div className='text-[#D9D9D9]'>All Rights Reserved</div>
      </div>
      </div>
      </div>
    </>
  );
};

export default Footer;
