import React, { useEffect, useRef, useState } from 'react';
import 'tailwindcss/tailwind.css'; // Import Tailwind CSS
import './SliderBanner.css'; // Import CSS file for custom styling if needed
import { Link } from 'react-scroll';
const images = [{
  img: '/HowWeDeliver/DigitalDesign.png',
  heading: "Design",
  "desp": "De-risking possibilities of failure considering various dimensions – stakeholder alignment, baselining existing digital infrastructure & partner nation digital health maturity, fit-for-purpose IP portfolio and business case"
},
{
  img: '/HowWeDeliver/Aggregator.png',
  heading: "Fund",
  "desp": "Establishing a business case that allows flexibility in financing and buffers for downstream events & reduces government’s investment burden"
},
{
  img: '/HowWeDeliver/Implementation.png',
  heading: "Implement",
  "desp": "Combining expertise with compliance and governance along with enablers like training and change management for faster adoption"
},
{
  img: '/HowWeDeliver/FinancialSustainability.png',
  heading: "Monetize",
  "desp": "Value added services in collaboration with the private sector brings additional benefits to beneficiaries and strengthens business case"
}];

const SliderBanner = ({ visibleRef, setVisible }) => {
  // const [visibleRef, setVisible] = useState(null);

  const valuePropRef0 = useRef(null)
  const valuePropRef1 = useRef(null)
  const valuePropRef2 = useRef(null)
  const valuePropRef3 = useRef(null)
  const handleValueprop = () => {
    valuePropRef0.current?.scrollIntoView({ behavior: 'smooth' })
  }
  const handleValueprop1 = () => {
    valuePropRef1.current?.scrollIntoView({ behavior: 'smooth' })
  }
  const handleValueprop2 = () => {
    valuePropRef2.current?.scrollIntoView({ behavior: 'smooth' })
  }
  const handleValueprop3 = () => {
    valuePropRef3.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const [currentImage, setCurrentImage] = useState(0)


  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.4, // Trigger when 20% of the element is visible
    };

    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          console.log("5111", entry.target.getAttribute('name'))
          setVisible(entry.target.getAttribute('name'));
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);

    // Observe each ref
    [valuePropRef0, valuePropRef1, valuePropRef2, valuePropRef3].forEach(ref => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    // Clean up observer on unmount
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <>
      <div className='hidden lg:block'>
        {images.map((elem, mainIndex) =>
          <div name={elem.heading} ref={mainIndex === 0 ? valuePropRef0 : mainIndex === 1 ? valuePropRef1 : mainIndex === 2 ? valuePropRef2 : valuePropRef3} className="section h-screen  parallax-bg"> <div className="h-screen  relative parallax-bg "
            style={{
              'background-image':
                `linear-gradient(to top, rgba(0, 0, 0, 0.71), rgba(0, 0, 0, 0)) , url("${images[mainIndex].img}")`,
            }}>
            <div className="hidden lg:block absolute left-4 lg:left-10 top-[80%] lg:top-[70%] transform -translate-y-1/2 z-10">
              {images.map((_, index) => (

                <div
                  className={`${mainIndex === index ? 'h-4' : 'h-2'} w-2 rounded-full m-1 lg:cursor-pointer ${mainIndex === index ? 'bg-white' : 'bg-gray-500'
                    }`} onClick={() => {

                      if (index === 0) {
                        handleValueprop()
                      }
                      else if (index === 1) {
                        handleValueprop1()
                      }
                      else if (index === 2) {
                        handleValueprop2()
                      }
                      else if (index === 3) {
                        handleValueprop3()
                      }
                    }}
                />

              ))}
            </div>
            <div className={`block lg:hidden absolute left-4 lg:left-10 top-[80%] lg:top-[70%] transform -translate-y-1/2 z-${(mainIndex + 1) * 5}`}>
              {images.map((_, index) => (

                <div
                  key={index}
                  className={`${mainIndex === index ? 'h-4' : 'h-2'} w-2 rounded-full m-1 ${'bg-white'
                    }`}
                />
              ))}
            </div>
            <div className='hidden lg:block relative lg:top-[15%] h-[50px] left-20 text-white w-[68%] text-[35px] lg:text-[45px]'> {mainIndex === 0 ? 'Value Proposition' : null}</div>
            <div className={`hidden lg:block relative lg:top-[50%] left-20 text-white w-[68%] sectionSlider ${visibleRef === images[mainIndex].heading ? 'visible' : ''}`}>
              <div className='text-[35px] lg:text-[45px] flex flex-row items-center text-heading'> <div>{images[mainIndex].heading}</div> <hr className='ml-4 border-t-2 border-white w-[40%]' /></div>
              {/* <div className='text-heading text-[20px] lg:text-[30px] mb-4'>{images[mainIndex].heading}</div> */}
              <div className='text-content text-[20px] lg:text-[25px]'>{images[mainIndex].desp}</div>

            </div>
          </div>
          </div>
        )}
      </div>

      <div className="block lg:hidden px-4 h-screen my-auto">
        <div

          className="m-auto pt-14 w-[100%] flex justify-between flex-row text-center items-center sm:text-[26px] lg:text-[30px] md:text-[30px] mb-2"
        >
          <div
            className="text-[25px] lg:text-[36px] md:text-[32px] font-medium text-[#002475] mb-2 text-left"
          >
            Value Proposition
          </div>
          <hr className='border-top-[0.5px] border-black w-[30%]' />


        </div>
        <div className='mt-8 grid grid-rows-2 grid-cols-2 gap-4'>
          {images.map((elem, index) => (
            <div className={`p-4 text-center text-lg font-semibold rounded-lg w-full cursor-pointer ${currentImage === index ? 'bg-[#002475] text-white' : 'bg-[#F3F3F3] text-[#002475]'}`} onClick={() => {
              setCurrentImage(index)
            }}>
              {elem.heading}
            </div>
          ))}
        </div>

        <div className='mt-10 w-full h-80 bg-cover bg-center rounded-xl' style={{
          'background-image':
            `linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)) , url("${images[currentImage].img}")`,
        }}>
          <div className='relative flex flex-col justify-end h-full left-5 text-white w-[90%] pb-4'>

            <div className='text-[16px]'>{images[currentImage].desp}</div>

          </div>
        </div>
      </div>

    </>
  );
};

export default SliderBanner;