import React, { useState, useEffect } from 'react';

const SliderButtons = ({
  buttonClass,
  svgContainerClass,
  svgClass,
  targetId,
  mainContainerClass,
  slide
}) => {
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  useEffect(() => {
    const target = document.querySelector(`#${targetId}`);
    setCanScrollLeft(target.scrollLeft > 0);
    setCanScrollRight(target.scrollLeft < target.scrollWidth - target.clientWidth);
    
    const handleScroll = () => {
      setCanScrollLeft(target.scrollLeft > 0);
      setCanScrollRight(target.scrollLeft < target.scrollWidth - target.clientWidth);
    };

    target.addEventListener('scroll', handleScroll);
    return () => {
      target.removeEventListener('scroll', handleScroll);
    };
  }, [targetId]);

  return (
    <div className={mainContainerClass}>
      <button
        className={`${buttonClass} ${canScrollLeft ? 'border-black' : 'border-gray-400 disabled'}`}
        onClick={() => {
          const target = document.querySelector(`#${targetId}`);
          target.scrollLeft -= window.innerWidth / 2 > 600 ? slide === "full" ? window.innerWidth : window.innerWidth / 4 : window.innerWidth - 90;
        }}
        disabled={!canScrollLeft}
      >
        <span className={svgContainerClass}>
          <svg className={`${svgClass} ${canScrollLeft ? '' : 'text-gray-400'}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m15 19-7-7 7-7"/>
          </svg>
        </span>
      </button>
      <button
        className={`${buttonClass} ${canScrollRight ? 'border-black' : 'border-gray-400 disabled'}`}
        onClick={() => {
          const target = document.querySelector(`#${targetId}`);
          target.scrollLeft += window.innerWidth / 2 > 600 ? slide === "full" ? window.innerWidth : window.innerWidth / 4 : window.innerWidth - 90;
        }}
        disabled={!canScrollRight}
      >
        <span className={svgContainerClass}>
          <svg className={`${svgClass} ${canScrollRight ? '' : 'text-gray-400'}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m9 5 7 7-7 7"/>
          </svg>
        </span>
      </button>
    </div>
  );
};

export default SliderButtons;
