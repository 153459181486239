import React, { useState } from 'react';
import "./Navbar.css";
import logo from "../media/logo.png";
import { Link } from 'react-scroll';
import { MdClose } from "react-icons/md";
import { RxHamburgerMenu } from "react-icons/rx";

const Navbar = ({visibleRef,handleNavbarClick,handleAbout,handleChallenges,handleDigitalization,handleValueprop,handleStakehoder}) => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const handleMobileMenuToggle = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };
    const handleEmailClick = () => {
        const email = 'info@apeiro.digital';
        const mailtoLink = `mailto:${email}`;
        window.location.href = mailtoLink;
    };
    return (
        <nav className="sticky top-0 bg-white z-50 items-center text-xl lg:flex w-full p-2 lg:p-0  justify-between">
            <div className="flex items-center justify-between">
                <div className="text-black font-bold text-lg md:mb-0 ml-2 lg:ml-10 cursor-pointer " onClick={()=>{handleNavbarClick();
                setIsMobileMenuOpen(false)}}>
               <img src={logo} alt="Logo" className="w-[51px] h-[32px] lg:h-12 lg:w-20" />
                </div>

                <div className="block w-10 h-10 p-2 cursor-pointer lg:hidden" onClick={handleMobileMenuToggle}>
                    {isMobileMenuOpen?
                       <MdClose />: 
                <RxHamburgerMenu />
}
                </div>
            </div>
            <ul
                className={
                    (isMobileMenuOpen ? "right-0 " : "-right-full ") +
                    " w-[80%] lg:static fixed bottom-0 top-14 flex flex-col lg:flex-row lg:space-x-7 text-gray-500 text-[#808080] lg:space-y-0 space-y-5 pt-6 lg:py-3 lg:pl-24 transition-left delay-75 duration-300 ease-in lg:items-center lg:justify-between text-[16px] bg-white lg:pr-8"
                }
                style={{fontWeight:500}}
            > <div className={`cursor-pointer mx-auto lg:mx-0 ${visibleRef==='about'?'text-[#002475]':''}`} onClick={()=>{
                handleAbout();
                handleMobileMenuToggle()
            }}>
                   About Us
                </div>
                <div className={`cursor-pointer mx-auto  lg:mx-0 ${visibleRef==='healthcarechallenges'?'text-[#002475]':''}`} onClick={()=>{
                    handleChallenges()
                    handleMobileMenuToggle()
                }}>

                   
                    What We See
                </div>
                <div className={`cursor-pointer mx-auto  lg:mx-0 ${visibleRef==='digitalization'?'text-[#002475]':''}`} onClick={()=>{
                    handleDigitalization()
                    handleMobileMenuToggle()
                }}>
                    Digitalization
                </div>
                <div className={`cursor-pointer mx-auto  lg:mx-0 ${visibleRef==='Design' || visibleRef==='Funding' || visibleRef==='Implementation' || visibleRef==='Monetization'?'text-[#002475]':''}`} onClick={()=>{
                    handleValueprop();
                    handleMobileMenuToggle()
                }}>
                    Value Proposition
                </div>
                <div className={`cursor-pointer mx-auto  lg:mx-0 ${visibleRef==='stakeholders'?'text-[#002475]':''}`} onClick={()=>{
                    handleStakehoder()
                    handleMobileMenuToggle()
                }}>
                    Solutions
                </div>
                {/* <div className='cursor-pointer' >
                    <Link to="partners" smooth={true} onClick={handleMobileMenuToggle}>
                        Our Partners
                    </Link>
                </div> */}
                  <div className='w-[129px] h-[32px] lg:w-[151px] lg:h-[50px] flex rounded-3xl buton2 mx-auto'>
                <button className="w-[125px] h-[28px] lg:w-[147px] lg:h-[46px] m-auto bg-white rounded-3xl text-[16px] buton px-[0px]" onClick={handleEmailClick}>Contact Us</button>

                </div>
            </ul>
            {/* <div className="hidden md:block">
                <button className="hidden md:block px-6 py-3 buton buton2 font-customFont text-[16px] text-white rounded-3xl" onClick={handleEmailClick}>Contact Us</button>

            </div> */}


        </nav>


    );
};

export default Navbar;
