import React from "react";
import SliderButtons from "../components/SliderButtons";
import { Blogs } from "../components/Slider";

function Healthcarechallenges() {
	const cards = [
        {
          id: 1,
          imgSrc: '/healthcare/treatmentCost.jpg',
          title: 'Rising Treatment Cost',
		  description:"Hospital healthcare spending reached <span class='font-bold'>$11.2</span> trillion in <span class='font-bold'>2023</span>, and is anticipated to continue rising, reflecting an upward trend in healthcare costs."
        },
        {
          id: 2,
          imgSrc: '/healthcare/chronicDisease.jpg',
          title: 'Chronic Disease Burden',
		  description:"Chronic diseases, with their combined effects on the economy, productivity, healthcare, social care, and psychological well-being, could incur a staggering <span class='font-bold'>$47</span> trillion loss to the global economy by <span class='font-bold'>2030</span>."
        },
        {
          id: 3,
          imgSrc: '/healthcare/medicationError.jpg',
          title: 'Medication Errors',
		  description:"Globally, medication errors cost an estimated <span class='font-bold'>$42 billion</span>, making them a leading cause of avoidable harm in healthcare systems."
        },
        {
          id: 4,
          imgSrc: '/healthcare/universalHealth.jpg',
          title: 'Universal Health Coverage Gap',
		  description:"Nearly <span class='font-bold'>4.5 billion</span> people don't have access to full UHC."
        },
        {
            id: 5,
            imgSrc: '/healthcare/NeglectedTropicalDiseases.jpg',
            title: 'Neglected Tropical Diseases (NTDs)',
			description:"Over <span class='font-bold'>1.5 billion</span> people suffer from infectious disease every year."
          },
          {
            id: 6,
            imgSrc: '/healthcare/essentialMedicine.jpg',
            title: 'Essential Medicine',
			description:"Over <span class='font-bold'>2</span> billion people lack essential medicines due to challenges such as availability, affordability, accessibility, standards, or quality, impacting more than <span class='font-bold'>25%</span> of the global population."
          }
      ];
	return (
    <div id="healthcare" className="pt-6">
		<div className=" lg:w-[1140px] 2xl:w-[1680px] m-auto px-4" >
		<div
			
			className="m-auto pt-14 w-[100%] h-[100%] flex justify-between flex-row text-center items-center sm:text-[26px] lg:text-[30px] md:text-[30px] mb-2"
		>
			<div
				className="text-[25px] lg:text-[36px] md:text-[32px] font-medium text-[#002475] mb-2 text-left"
			>
				Healthcare Challenges
			</div>
			<hr className='hidden lg:block border-top-[0.5px] border-black w-[30%] lg:w-[50%]'/>
			<div className='hidden lg:block'>
        <SliderButtons
          mainContainerClass=' flex items-end gap-3 lg:gap-5 4k:gap-7 pb-1'
          buttonClass=' outline-none uppercase text-black border-2 bg-light rounded-full hover:bg-gray-200 p-1 lg:p-3 '
          svgClass='h-6 4k:h-12 w-6 4k:w-12 '
          svgContainerClass=' grid place-items-center '
          targetId='blogs-card'
		  slide={"half"}
        />
      </div>
	  
		</div>
    <div className="block lg:hidden text-[20px] leading-6" style={{fontWeight:350}}>
		Governments worldwide confront numerous challenges in the realm of health, including fragmented systems, inequities in access, and emerging threats to public well-being.
		</div>
		<div className="hidden lg:block text-[20px] leading-6" style={{fontWeight:350}}>
		Governments worldwide confront numerous challenges in the realm of health, including  
		</div>
		<div className="hidden lg:block text-[20px] leading-6" style={{fontWeight:350}}>
		fragmented systems, inequities in access, and emerging threats to public well-being.
		</div>
		<Blogs cards={cards} targetId="blogs-card" subtitle={false}/>
		</div>
    </div>
	);
}

export default Healthcarechallenges;
