import React, { useState, useRef, useEffect } from "react";
import Dots from "react-carousel-dots";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Pagination, Autoplay } from 'swiper/modules';
// Import Swiper styles

function Whoweare() {
	const [toggleTableChart, setToggleTableChart] = useState(0);
	const swiperRef = useRef(null);
	const containerRef = useRef(null); // Ref for the container
	const [leaders, setLeaders] = useState([
		{
			name: "FARAH SHAIKH",
			designation: "Chief Executive Officer",
			description: "Farah Shaikh, a global healthcare leader, has more than 15+ years' experience in the government and private sector and has pioneered developing tech-enabled and financially sustainable PPP models for governments. She has established herself as one of the leading women in healthcare through her ability to grasp healthcare concerns and create ground-breaking, pragmatic solutions.",
			image: "FarahShaikh.png"
		},
		// {
		// 	name: "AMIT SADANAv2",
		// 	designation: "Chief Strategy and Operating Officer",
		// 	description: "Amit Sadana is an innovative, pragmatic, and entrepreneurial global healthcare executive with experience of working with diverse customers across the US, Europe, Africa, the Middle East, and India. He successfully exited a 300-member healthcare data analytics start-up that was acquired in 2012 by IMS Health.",
		// 	image: "OsamaMalki.png"
		// },
		{
			name: "OSAMA MALKI",
			designation: "Chief Business Officer",
			description: "Osama Malki boasts an extensive 14-year tenure in the healthcare sector. His career trajectory has been marked by spearheading transformative initiatives across the AMESA (Africa, the Middle East, and South Asia) regions. He has successfully steered strategic, flagship national, and region-wide digital transformation programs.",
			image: "OsamaMalki.png"
		},
		{
			name: "RAJ VEERAVALLI",
			designation: "Vice President - Strategy and Monetization",
			description: "Raj Veeravalli, with 20 years of experience in Healthcare and Life-Sciences, has led digital transformation programs for life-sciences clients in emerging markets, scaled technology platforms for doctor and patient engagement, and has overseen service delivery and support for a leading health analytics provider. At BCG, he was a part of strategic advisory projects for consumer goods, financial services, and multi-lateral clients.",
			image: "RajVeeravalli.png"
		}
	]);
	console.log("leaders", leaders)
	// For checking is swiper component is in viewport or not
	useEffect(() => {
		const observer = new IntersectionObserver((entries) => {
			if (entries[0].isIntersecting) {
				swiperRef.current.swiper.slideTo(0);
			}
		});

		if (containerRef.current) {
			observer.observe(containerRef.current);
		}

		return () => {
			// Clean up observer when component unmounts
			observer.disconnect();
		};
	}, []);


	return (
		<div
			id="whoweare"
			className=" w-[100%] h-[100vh]  flex flex-col justify-center text-center justify-items-center items-center"
		>
			<div
				className=" text-center text-[#002475] text-[32px] lg:text-[48px] md:text-[48px] mt-20 mb-4" style={{ fontWeight: 600 }}
			>
				Leadership Team
			</div>



			<div className="lg:hidden w-[100%] lg:px-[120px]" ref={containerRef} onMouseEnter={() => {
				// Access Swiper instance and stop autoplay

			}}>
				<Swiper
					slidesPerView={1}
					// 					autoplay={{
					// 	delay: 2000,
					// 	disableOnInteraction: false,
					// }}
					ref={swiperRef}

					speed={2000}
					modules={[Pagination]}
					pagination={{
						clickable: true,
					}}
				>
					<SwiperSlide className="w-[100%]">
						<div className=" pb-8 lg:pb-0">
							<div className="w-[100%] object-cover p-4">

								<div class="relative w-[100%]  rounded-2xl overflow-hidden group cursor-pointer">
									<img class="w-full rounded-lg overflow-hidden transition-transform duration-300" src={leaders[0].image} alt="j3" />
									<div class="absolute inset-0 bg-gradientob opacity-0 transition-opacity duration-300 group-hover:opacity-75"></div>
									<div class="absolute inset-0 bg-gradientnormal opacity-100 transition-opacity duration-300 group-hover:opacity-0"></div>

									<div class="absolute text-2xl text-blue-300 bottom-5 left-5">
										<h3 class="text-[16px] lg:text-[20px] text-start  font-medium text-[#FFFFFF] ">
											{leaders[0].name}
										</h3>
										<div class="group-hover:h-auto group-hover:duration-400 group-hover:transition group-hover:delay-700 h-[15px] relative">
											<h3 class="text-[10px] md:text-[14px] text-start  font-medium text-[#FFFFFF] ">
												{leaders[0].designation}
											</h3>
											<h3 class="text-[12px] md:text-[14px] mt-3 text-start font-light text-[#FFFFFF] animated-text ">
												{leaders[0].description}
											</h3>
										</div>
									</div>

								</div>
							</div>
						</div>
					</SwiperSlide>
					<SwiperSlide className="w-[100%]">
						<div className="pb-8 lg:pb-0">
							<div className="w-[100%] object-cover p-4">

								<div class="relative w-[100%]  rounded-2xl overflow-hidden group cursor-pointer">
									<img class="w-full rounded-lg overflow-hidden transition-transform duration-300" src={leaders[1].image} alt="j3" />
									<div class="absolute inset-0 bg-gradientob opacity-0 transition-opacity duration-300 group-hover:opacity-75"></div>
									<div class="absolute inset-0 bg-gradientnormal opacity-100 transition-opacity duration-300 group-hover:opacity-0"></div>

									<div class="absolute text-2xl text-blue-300 bottom-5 left-5">
										<h3 class="text-[16px] lg:text-[20px] text-start  font-medium text-[#FFFFFF] ">
											{leaders[1].name}
										</h3>
										<div class="group-hover:h-auto group-hover:duration-400 group-hover:transition group-hover:delay-700 h-[15px] relative">
											<h3 class="text-[10px] md:text-[14px] text-start  font-medium text-[#FFFFFF] ">
												{leaders[1].designation}
											</h3>
											<h3 class="text-[12px] md:text-[14px] mt-3 text-start font-light text-[#FFFFFF] animated-text ">
												{leaders[1].description}
											</h3>
										</div>
									</div>

								</div>
							</div>
						</div>
					</SwiperSlide>

					<SwiperSlide className="w-[100%]">
						<div className="pb-8 lg:pb-0">
							<div className="w-[100%] object-cover p-4">

								<div class="relative w-[100%]  rounded-2xl overflow-hidden group cursor-pointer">
									<img class="w-full rounded-lg overflow-hidden transition-transform duration-300" src={leaders[2].image} alt="j3" />
									<div class="absolute inset-0 bg-gradientob opacity-0 transition-opacity duration-300 group-hover:opacity-75"></div>
									<div class="absolute inset-0 bg-gradientnormal opacity-100 transition-opacity duration-300 group-hover:opacity-0"></div>

									<div class="absolute text-2xl text-blue-300 bottom-5 left-5">
										<h3 class="text-[16px] lg:text-[20px] text-start  font-medium text-[#FFFFFF] ">
											{leaders[2].name}
										</h3>
										<div class="group-hover:h-auto group-hover:duration-400 group-hover:transition group-hover:delay-700 h-[15px] relative">
											<h3 class="text-[10px] md:text-[14px] text-start  font-medium text-[#FFFFFF] ">
												{leaders[2].designation}
											</h3>
											<h3 class="text-[12px] md:text-[14px] mt-3 text-start font-light text-[#FFFFFF] animated-text ">
												{leaders[2].description}
											</h3>
										</div>
									</div>

								</div>
							</div>
						</div>
					</SwiperSlide>
					{/* <SwiperSlide className="w-[100%]">
						<div className="pb-8 lg:pb-0">
							<div className="w-[100%] object-cover p-4">

								<div class="relative w-[100%]  rounded-2xl overflow-hidden group cursor-pointer">
									<img class="w-full rounded-lg overflow-hidden transition-transform duration-300" src={leaders[3].image} alt="j3" />
									<div class="absolute inset-0 bg-gradientob opacity-0 transition-opacity duration-300 group-hover:opacity-75"></div>
									<div class="absolute inset-0 bg-gradientnormal opacity-100 transition-opacity duration-300 group-hover:opacity-0"></div>

									<div class="absolute text-2xl text-blue-300 bottom-5 left-5">
										<h3 class="text-[16px] lg:text-[20px] text-start  font-medium text-[#FFFFFF] ">
											{leaders[3].name}
										</h3>
										<div class="group-hover:h-auto group-hover:duration-400 group-hover:transition group-hover:delay-700 h-[15px] relative">
											<h3 class="text-[10px] md:text-[14px] text-start  font-medium text-[#FFFFFF] ">
												{leaders[3].designation}
											</h3>
											<h3 class="text-[12px] md:text-[14px] mt-3 text-start font-light text-[#FFFFFF] animated-text ">
												{leaders[3].description}
											</h3>

										</div>
									</div>

								</div>
							</div>
						</div>
					</SwiperSlide> */}

				</Swiper>
				<div className="swiper-pagination">
				</div>
			</div>
			<div className="hidden lg:flex w-[100%] h-[100%] lg:px-[160px] flex-col gap-3 lg:flex-row">
				{leaders.map((leader) =>
					<div class="md:w-[100%] lg:w-[33%] my-auto object-cover sm:p-4 md:p-0">
						<div class="relative w-[100%] rounded-2xl overflow-hidden group cursor-pointer" >
							{/* <div class="group-hover:bg-black group-hover:bg-opacity-50 absolute inset-0 rounded-lg group-hover:bg-gradient-to-b from-transparent to-black"></div> */}
							<img class="w-full rounded-lg overflow-hidden transition-transform duration-300" src={leader.image} alt="j3" />
							<div class="absolute inset-0 bg-gradientob opacity-0 transition-opacity duration-300 group-hover:opacity-75"></div>
							<div class="absolute inset-0 bg-gradientnormal opacity-100 transition-opacity duration-300 group-hover:opacity-0"></div>
							<div class="absolute text-2xl text-blue-300 bottom-9 group-hover:top-5 ml-5 mr-5">
								<h3 class="text-[24px] font-customFont text-start font-medium text-[#FFFFFF] ">
									{leader.name}
								</h3>
								<div class="group-hover:h-auto group-hover:duration-400 group-hover:transition group-hover:delay-700 h-[15px] relative">
									<h3 class="text-[16px] font-customFont text-start font-medium text-[#FFFFFF] ">
										{leader.designation}
									</h3>
									<h3 class="text-[14px] mt-3 font-customFont text-start font-light text-[#FFFFFF] animated-text leading-[18px]">
										{leader.description}
									</h3>
								</div>
							</div>
						</div>
					</div>
				)}



			</div>


		</div>
	);
}
export default Whoweare;
